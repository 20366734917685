<template>
  <div class="navbar-container">
    <nav>
      <router-link to="/" class="logo">Anna Banana Bakery</router-link>

      <router-link to="/cart" class="shopping-cart"
        >Cart:
        <p>{{ cartCount }}</p>
      </router-link>
    </nav>
  </div>
</template>
<script>
import { useCartStore } from '../stores/CartStore';
import { storeToRefs } from 'pinia';
export default {
  setup() {
    const cartStore = useCartStore();

    // Turns the store into a ref so that instead of using cartStore.cartItems, we can use cartItems directly
    const { cartCount } = storeToRefs(cartStore);

    // cartStore is available for further operations if needed

    return {
      cartCount,
    };
  },
};
</script>
<style scoped>
.navbar-container {
  background-color: var(--background);
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Adjusted to use rgba for better visibility */
}
nav {
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}
nav a {
  color: #333;
  text-decoration: none;
  font-size: 1.5rem;
}
.logo {
  font-family: 'Alex Brush', cursive;
  font-weight: 400;
  font-size: 3rem;
}
.shopping-cart {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center; /* Added to center the content horizontally */
}
.shopping-cart p {
  margin-left: 5px;
  font-size: 1.5rem;
}

/* Mobile Section */
@media screen and (max-width: 600px) {
  .logo {
    font-size: 2rem;
  }
  .shopping-cart {
    padding-left: 5px;
    font-size: 1.2rem;
  }
  .shopping-cart p {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 360px) {
  .logo {
    font-size: 1.5rem;
  }
  .shopping-cart {
    padding-left: 5px;
    font-size: 1rem;
  }
  .shopping-cart p {
    font-size: 1rem;
  }
}
</style>
