<template>
  <div class="app">
    <Navbar class="navbar" />
    <router-view />
    <Footer class="footer" />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Footer from './components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
<style scoped>
.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: visible;
}
.app > .navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.app > .footer {
  margin-top: auto;
}
</style>
