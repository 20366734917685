<template>
  <div class="checkout-container" v-if="!submitSuccessful && !error">
    <h1>Checkout</h1>
    <div class="checkout-list">
      <div
        v-if="cartCount != 0"
        v-for="item in cart"
        :key="item.id"
        class="checkout-item"
      >
        <CartProductDetails :product="item" />
      </div>

      <div v-if="cartCount == 0" class="empty-cart">
        Your cart is empty!
        <router-link to="/"><button>Return to Catalog</button></router-link>
      </div>
    </div>
    <div v-if="cartCount != 0" class="cart-total">
      <span class="total">Total: ${{ cartTotal }}</span>
      <span class="shipping">*Does not include shipping</span>
    </div>

    <div class="contact-info">
      <h2>Delivery Information</h2>
      <p>Email: {{ contact.email }}</p>
      <p>Phone Number: {{ contact.phoneNumber }}</p>
      <p>Delivery Date: {{ contact.deliveryDate }}</p>
      <p>Delivery Time: {{ contact.deliveryTime }}</p>
      <p>Delivery Address:</p>
      <div class="address">
        <span>Country: {{ contact.country }}</span>
        <span>State: {{ contact.state }}</span>
        <span>City: {{ contact.city }}</span>
        <span
          >Street address: <br />
          {{ contact.streetAddress }}</span
        >
        <span>Zip: {{ contact.zipCode }}</span>
      </div>
      <div class="button-back">
        <i class="material-icons" @click="handleClick">arrow_back</i>
        <span>Edit</span>
      </div>
    </div>

    <div v-if="cartCount > 0">
      <button v-if="!isPending" @click="submitOrder({ cart, contact })">
        Place Order
      </button>
      <div v-if="isPending" disabled class="loading">Loading...</div>
    </div>
  </div>

  <div class="error" v-if="error">
    <h3>There was an error submitting your order</h3>
  </div>
  <div v-if="submitSuccessful" class="successMessage">
    <h1>Order Received!</h1>
    <p>
      Your order has been received and I will contact you via email as soon as
      possible.
    </p>
    <p>Thank you very much!</p>
    <router-link to="/"> <button>Return to Home</button> </router-link>
  </div>
</template>

<script>
import CartProductDetails from '@/components/CartProductDetails.vue';
import { useContactStore } from '@/stores/ContactStore';
import { useCartStore } from '@/stores/CartStore';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { timestamp } from '@/firebase/config';
import useCollection from '@/utils/useCollection';
import router from '@/router';

export default {
  components: {
    CartProductDetails,
  },
  setup() {
    const contactStore = useContactStore();
    const cartStore = useCartStore();
    const { cart, cartCount, cartTotal } = storeToRefs(cartStore);
    const { contact } = storeToRefs(contactStore);
    const { error, addDoc } = useCollection('orders');
    const isPending = ref(false);
    const submitSuccessful = ref(false);

    const handleClick = () => {
      router.back();
    };

    const submitOrder = async ({ cart, contact }) => {
      isPending.value = true;

      await addDoc({
        cart,
        cartTotal: cartTotal.value,
        contact,
        status: 'Pending',
        createdAt: timestamp(),
      });

      if (error.value) {
        console.log(error.value);
      }
      if (!error.value) {
        contactStore.clearContact();
        cartStore.clearCart();
        submitSuccessful.value = true;
      }
      isPending.value = false;
    };

    return {
      cart,
      cartCount,
      cartTotal,
      contact,
      error,
      submitOrder,
      handleClick,
      isPending,
      submitSuccessful,
    };
  },
};
</script>

<style scoped>
.checkout-container {
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  align-items: center;
}
.cart-total {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.total {
  font-size: 20px;
  font-weight: bold;
  border: 1px solid var(--accent);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  text-align: center;
}
.shipping {
  color: grey;
}
.contact-info {
  width: 100%;
  margin-top: 20px;
}

p {
  font-size: 1.5rem;
  max-width: 600px;
}
.address {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  white-space: pre-line;
  border: solid 1px var(--accent);
  padding: 10px;
  border-radius: 5px;
  font-size: 1.5rem;
  max-width: 600px;
}
.button-back {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
  font-size: 1.5rem;
}
.button-back span {
  margin-left: 5px;
}
.button-back i {
  cursor: pointer;
}
button {
  margin: 20px 0;
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: var(--primary);
}
button:hover {
  border-radius: 5px;
  padding-right: 24px;
  padding-left: 8px;
}
button:hover:after {
  opacity: 1;
  right: 10px;
}
button:after {
  content: '\00BB';
  position: absolute;
  opacity: 0;
  font-size: 20px;
  line-height: 40px;
  top: 0;
  right: -20px;
  transition: 0.4s;
}

.loading {
  font-size: 2rem;
  margin-top: 20px;
}
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.successMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
}

/* Mobile Section */
@media (max-width: 600px) {
  .checkout-container {
    padding: 0 20px;
  }
  h2 {
    font-size: 1.5rem;
  }
  .contact-info p {
    font-size: 1rem;
  }
  .address {
    font-size: 1rem;
    max-width: 100%;
  }
  button {
    margin-bottom: 20px;
  }
  .button-back {
    font-size: 1rem;
  }
}
</style>
