import { defineStore } from 'pinia';

export const useContactStore = defineStore('contactStore', {
  state: () => ({
    contact: {
      email: '',
      phoneNumber: '',
      deliveryDate: '',
      deliveryTime: '',
      deliveryAddress: '',
    },
  }),
  actions: {
    setContact(contact) {
      this.contact = contact;
    },
    clearContact() {
      console.log('clearContact called');
      this.contact = {
        email: '',
        phoneNumber: '',
        deliveryDate: '',
        deliveryTime: '',
        deliveryAddress: '',
      };
      console.log('Contact after clearing:', this.contact);
    },
  },
});
