import { defineStore } from 'pinia';

export const useCartStore = defineStore('cartStore', {
  state: () => ({
    cart: [],
  }),
  getters: {
    cartCount: (state) => {
      return state.cart.length;
    },
    cartTotal: (state) => {
      return state.cart
        .reduce((total, item) => {
          let itemTotal = item.price * item.quantity;
          if (item.dozenDiscount && item.quantity >= 12) {
            const dozens = Math.floor(item.quantity / 12);
            itemTotal -= dozens * item.dozenDiscountAmount;
          }
          return total + itemTotal;
        }, 0)
        .toFixed(2);
    },
  },
  actions: {
    addToCart(item) {
      const existingItem = this.cart.find((t) => t.id === item.id);
      if (existingItem) {
        existingItem.quantity += item.quantity;
      } else {
        this.cart.push({ ...item, quantity: item.quantity });
      }
    },
    removeFromCart(id) {
      this.cart = this.cart.filter((t) => {
        return t.id !== id;
      });
    },
    clearCart() {
      console.log('clearCart called');
      this.cart = [];
      console.log('Cart after clearing:', this.cart);
    },
  },
});
