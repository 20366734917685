<template>
  <div class="cart-container">
    <h1>Selected Items</h1>
    <div class="cart-list">
      <div
        v-if="cartCount != 0"
        v-for="item in cart"
        :key="item.id"
        class="cart-item"
      >
        <CartProductDetails :product="item" />
      </div>
      <div v-if="cartCount != 0" class="cart-total">
        <span class="total">Total: ${{ cartTotal }}</span>
      </div>
      <div v-if="cartCount == 0" class="empty-cart">Your cart is empty!</div>

      <router-link to="/contact" v-if="cartCount > 0"
        ><button>Next Step</button></router-link
      >
      <router-link to="/" v-if="cartCount == 0"
        ><button>Return</button></router-link
      >
    </div>
  </div>
</template>

<script>
import CartProductDetails from '@/components/CartProductDetails.vue';
import { useCartStore } from '../stores/CartStore';
import { storeToRefs } from 'pinia';
export default {
  components: {
    CartProductDetails,
  },
  setup() {
    const cartStore = useCartStore();
    const { cart, cartCount, cartTotal } = storeToRefs(cartStore);

    return {
      cart,
      cartCount,
      cartTotal,
    };
  },
};
</script>

<style scoped>
.cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cart-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
}
.cart-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.empty-cart {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.cart-total {
  margin: 40px 0;
}
.total {
  font-size: 2rem;
  font-weight: bold;
}
button {
  font-size: 1rem;
  margin-top: 10px;
  min-width: 200px;
  height: 60px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: var(--primary);
}
button:hover {
  border-radius: 5px;
  padding-right: 24px;
  padding-left: 8px;
}
button:hover:after {
  opacity: 1;
  right: 10px;
}
button:after {
  content: '\00BB';
  position: absolute;
  opacity: 0;
  font-size: 20px;
  line-height: 40px;
  top: 0;
  right: -20px;
  transition: 0.4s;
}

@media (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }
  .empty-cart {
    font-size: 16px;
  }
  .total {
    font-size: 2rem;
  }
  button {
    margin: 10px 0;
  }
  .cart-item {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
