<template>
  <div class="home-container">
    <h2>Currently Available</h2>

    <!-- Filter Nav -->
    <nav class="filter">
      <button @click="filter = 'all'" :class="{ active: filter === 'all' }">
        All
      </button>
      <button
        @click="filter = 'Cookies'"
        :class="{ active: filter === 'Cookies' }"
      >
        Cookies
      </button>
      <button @click="filter = 'Bars'" :class="{ active: filter === 'Bars' }">
        Bars
      </button>
      <button @click="filter = 'Fudge'" :class="{ active: filter === 'Fudge' }">
        Fudge
      </button>
      <button
        @click="filter = 'Scones'"
        :class="{ active: filter === 'Scones' }"
      >
        Scones
      </button>
      <button @click="filter = 'Loaf'" :class="{ active: filter === 'Loaf' }">
        Loaf
      </button>
      <button
        @click="filter = 'Cookie Dough'"
        :class="{ active: filter === 'Cookie Dough' }"
      >
        Cookie Dough
      </button>
      <button
        @click="filter = 'Special'"
        :class="{ active: filter === 'Special' }"
      >
        Specials
      </button>
    </nav>

    <!-- Product List -->
    <div class="product-list" v-if="filter === 'all'">
      <div v-for="item in products" :key="item.id">
        <ProductDetails v-if="item" :product="item" />
      </div>
    </div>
    <div class="product-list" v-else>
      <div v-for="item in products" :key="item.id">
        <ProductDetails
          v-if="item && item.category === filter"
          :product="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import getCollection from '@/utils/getCollection';
import ProductDetails from '@/components/ProductDetails.vue';
export default {
  components: {
    ProductDetails,
  },
  setup() {
    const { documents: products } = getCollection('products');

    const filter = ref('all');

    return {
      products,
      filter,
    };
  },
};
</script>

<style scoped>
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
}
.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  max-width: 100vw;
  overflow: hidden;
}
h2 {
  text-align: center;
  color: var(--primary);
  margin: 0;
  margin-top: 60px;
  font-size: 2.5rem;
  font-weight: 700;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
}

.filter button {
  min-width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  border: 2px solid var(--primary);
  background: var(--primary);
  margin: 0 5px;
}

@media (max-width: 1100px) {
  .filter {
    flex-wrap: wrap;
  }
  .filter button {
    margin: 5px;
  }
}

.filter button:hover {
  background-color: var(--background);
  color: var(--primary);
}
.filter button.active {
  background-color: var(--background);
  color: var(--primary);
}

/* Mobile Section  */
@media (max-width: 600px) {
  .filter {
    flex-wrap: wrap;
  }
  .filter button {
    margin: 5px;
  }
}
</style>
