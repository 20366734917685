<template>
  <div class="product">
    <h3>{{ product.name }}</h3>
    <span class="price"
      >Price: ${{
        typeof product.price === 'number'
          ? product.price.toFixed(2)
          : product.price
      }}</span
    >
    <div class="quantity-selector">
      <button @click="decreaseQuantity">-</button>
      <div class="quantity">{{ product.quantity }}</div>
      <button @click="increaseQuantity">+</button>
    </div>
    <span class="price total">Total: ${{ calculateTotal(product) }}</span>
    <div class="icons">
      <i class="material-icons" @click="cartStore.removeFromCart(product.id)"
        >delete</i
      >
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useCartStore } from '../stores/CartStore';
import { storeToRefs } from 'pinia';

export default {
  props: ['product'],
  setup(props) {
    const cartStore = useCartStore();

    const calculateTotal = (product) => {
      let total = product.price * product.quantity;
      if (product.dozenDiscount) {
        const dozens = Math.floor(product.quantity / 12);
        total -= dozens * product.dozenDiscountAmount;
      }
      return total.toFixed(2);
    };

    const increaseQuantity = () => {
      props.product.quantity++;
    };

    const decreaseQuantity = () => {
      if (props.product.quantity > 1) {
        props.product.quantity--;
      }
    };

    return {
      decreaseQuantity,
      increaseQuantity,
      calculateTotal,
      cartStore,
    };
  },
};
</script>

<style scoped>
.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 800px;
  height: 50px;
}

.quantity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 150px;
  margin: 0 auto;
}
.quantity-selector button {
  background-color: var(--primary);
  color: white;
  border: none;
  height: 30px;
  width: 30px;
  font-size: 1.2rem;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
h3 {
  font-size: 1.2rem;
  width: 300px;
  display: inline-block;
  line-height: 50px;
  margin-right: 10px;
}
.price {
  width: 130px;
  margin: 0;
  display: inline-block;
  height: 100%;
  font-size: 1.2rem;
  line-height: 50px;
  padding-left: 10px;
  text-align: left;
}
.quantity {
  width: 15px;
}
.icons {
  display: flex;
  gap: 10px;
}
button {
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}
button:hover {
  background-color: var(--accent);
}
i {
  cursor: pointer;
}

@media (max-width: 600px) {
  .product {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: auto;
    margin-bottom: 20px;
    padding: 5px;
    float: left;
  }
  h3 {
    width: 100%;
    height: auto;
    font-size: 1rem;
    margin: 0;
    line-height: 1;
  }
  .price {
    width: 100%;
    text-align: center;
    font-size: 1rem;
  }
  .quantity-selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }
  .quantity-selector button {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    color: var(--text);
    font-size: 1.5rem;
    border: 1px solid var(--primary);
  }
  .quantity-selector button:first-child {
    order: 3;
  }
  .quantity-selector button:last-child {
    order: 1;
  }
  .quantity {
    text-align: center;
    order: 2;
  }
  .total {
    display: none;
  }
  .icons {
    padding-left: 10px;
  }
}
</style>
