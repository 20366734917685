import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCt6ckMfgFYhv3wiwK2Lrmz69DIyLWcm-0',
  authDomain: 'bakery-page.firebaseapp.com',
  projectId: 'bakery-page',
  storageBucket: 'bakery-page.appspot.com',
  messagingSenderId: '766885840314',
  appId: '1:766885840314:web:39b754be1baf67ac560983',
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init firestore service
const projectFirestore = firebase.firestore();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectFirestore, timestamp };
