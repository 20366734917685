<template>
  <div class="product-container">
    <div class="product">
      <img :src="product.pictureUrl" :alt="product.name" />
      <h3>{{ product.name }}</h3>
      <p class="description">{{ product.description }}</p>
      <p class="price">
        Price: ${{
          typeof product.price === 'number'
            ? product.price.toFixed(2)
            : product.price
        }}
      </p>
      <div class="quantity-selector">
        <button @click="decreaseQuantity" class="quantity-button">-</button>
        <div>{{ quantity }}</div>
        <button @click="increaseQuantity" class="quantity-button">+</button>
      </div>
      <button @click="addToCart">Add To Cart</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useCartStore } from '../stores/CartStore';
import { storeToRefs } from 'pinia';

export default {
  props: ['product'],
  setup(props) {
    const quantity = ref(0);

    const cartStore = useCartStore();

    const { cart } = storeToRefs(cartStore);

    const increaseQuantity = () => {
      quantity.value++;
    };

    const decreaseQuantity = () => {
      if (quantity.value > 0) {
        quantity.value--;
      }
    };

    const addToCart = () => {
      if (quantity.value === 0) {
        return;
      }
      cartStore.addToCart({
        id: props.product.id,
        name: props.product.name,
        description: props.product.description,
        price: props.product.price,
        dozenDiscount: props.product.dozenDiscount,
        dozenDiscountAmount: props.product.dozenDiscountAmount,
        quantity: quantity.value,
        ingredients: props.product.ingredients,
        pictureUrl: props.product.pictureUrl,
      });
      quantity.value = 0;
    };

    return {
      quantity,
      increaseQuantity,
      decreaseQuantity,
      addToCart,
      cart,
    };
  },
};
</script>

<style scoped>
.product-container {
  height: 400px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  padding: 10px;
  border: solid 1px var(--accent);
  border-radius: 5px;
  background-color: transparent;
}
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.product p {
  min-height: 22px;
  margin: 0;
  margin-bottom: 10px;
  font-size: 1rem;
  text-align: center;
}
.product h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.5rem;
  text-align: center;
  font-size: 1.2rem;
  white-space: nowrap;
}
.product img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid var(--accent);
}
.quantity-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  justify-content: space-evenly;
  border-radius: 5px;
}
.quantity-selector button {
  background-color: var(--primary);
  height: 40px;
  width: 40px;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  font-size: 1.5rem;
}
.quantity-selector button:hover {
  background-color: var(--secondary);
}
.quantity-selector div {
  font-size: 1.5rem;
}

button {
  width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  border-radius: 5px;
  border: none;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  background: var(--primary);
  z-index: 1;
}
button:hover {
  background-color: var(--secondary);
}
button:hover:after {
  width: 100%;
  left: 0;
}
button:after {
  border-radius: 5px;
  position: absolute;
  content: '';
  width: 0;
  height: 100%;
  top: 0;
  z-index: -1;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: var(--secondary);
  right: 0;
}
button:active {
  top: 2px;
}

.quantity-selector button {
  width: 40px;
  height: 40px;
  box-shadow: none;
  transition: none;
  z-index: 1;
}
.quantity-selector button:hover::after {
  width: 0;
}
</style>
