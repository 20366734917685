<template>
  <form @submit.prevent="handleSubmit">
    <h2>Delivery Information</h2>
    <div class="row">
      <div class="row-group">
        <label for="email"
          >Email <span class="required-message">*required</span></label
        >
        <input type="email" id="email" v-model="email" required />
      </div>
      <div class="row-group">
        <label for="phoneNumber"
          >Phone Number<span class="required-message">*required</span></label
        >
        <input type="tel" id="phoneNumber" v-model="phoneNumber" required />
      </div>
    </div>

    <div class="row">
      <div class="row-group">
        <label for="country"
          >Country<span class="required-message">*required</span></label
        >
        <input type="text" id="country" v-model="country" required />
      </div>
      <div class="row-group">
        <label for="state"
          >State/Province<span class="required-message">*required</span></label
        >
        <input type="text" id="state" v-model="state" required />
      </div>
    </div>

    <div class="row">
      <div class="row-group">
        <label for="city"
          >City<span class="required-message">*required</span></label
        >
        <input type="text" id="city" v-model="city" required />
      </div>
      <div class="row-group">
        <label for="streetAddress"
          >Street address<span class="required-message">*required</span></label
        >
        <input
          type="text"
          id="streetAddress"
          v-model="streetAddress"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="row-group">
        <label for="zipCode">Zip Code</label>
        <input type="text" id="zipCode" v-model="zipCode" class="zipCode" />
      </div>
    </div>
    <div class="row">
      <div class="row-group">
        <label for="deliveryDate">Delivery Date</label>
        <input type="date" id="deliveryDate" v-model="deliveryDate" />
      </div>
      <div class="row-group">
        <label for="deliveryTime">Delivery Time</label>
        <input type="time" id="deliveryTime" v-model="deliveryTime" />
      </div>
    </div>

    <button @click="handleSubmit">To Checkout</button>
  </form>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useContactStore } from '@/stores/ContactStore';
import { storeToRefs } from 'pinia';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const contactStore = useContactStore();
    const { contact } = storeToRefs(contactStore);

    const email = ref('');
    const phoneNumber = ref('');
    const deliveryDate = ref('');
    const deliveryTime = ref('');
    const country = ref('');
    const state = ref('');
    const city = ref('');
    const streetAddress = ref('');
    const zipCode = ref('');

    onMounted(() => {
      email.value = contact.value.email;
      phoneNumber.value = contact.value.phoneNumber;
      deliveryDate.value = contact.value.deliveryDate;
      deliveryTime.value = contact.value.deliveryTime;
      country.value = contact.value.country;
      state.value = contact.value.state;
      city.value = contact.value.city;
      streetAddress.value = contact.value.streetAddress;
      zipCode.value = contact.value.zipCode;
    });

    const handleSubmit = () => {
      if (
        !email.value ||
        !phoneNumber.value ||
        !country.value ||
        !state.value ||
        !city.value ||
        !streetAddress.value
      ) {
        return;
      }
      contactStore.setContact({
        email: email.value,
        phoneNumber: phoneNumber.value,
        deliveryDate: deliveryDate.value,
        deliveryTime: deliveryTime.value,
        country: country.value,
        state: state.value,
        city: city.value,
        streetAddress: streetAddress.value,
        zipCode: zipCode.value || '',
      });
      console.log(contact.value);
      router.push('/checkout');
    };

    return {
      email,
      phoneNumber,
      deliveryDate,
      deliveryTime,
      country,
      state,
      city,
      streetAddress,
      zipCode,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
}
.row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 500px;
  margin-bottom: 1rem;
  height: 50px;
}
.row-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 1rem;
}
.required-message {
  color: grey;
  font-size: 0.7rem;
  padding-left: 10px;
}

label {
  font-size: 1rem;
  margin-right: 10px;
  width: 100%;
  text-align: left;
}
input {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}
input#zipCode.zipCode {
  width: 25%;
}
button {
  margin-top: 10px;
  width: 130px;
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  border: none;
  background-color: var(--primary);
}
button:hover {
  border-radius: 5px;
  padding-right: 24px;
  padding-left: 8px;
}
button:hover:after {
  opacity: 1;
  right: 10px;
}
button:after {
  content: '\00BB';
  position: absolute;
  opacity: 0;
  font-size: 20px;
  line-height: 40px;
  top: 0;
  right: -20px;
  transition: 0.4s;
}

/* Mobile Section */
@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
  form {
    width: 80%;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    padding: 10px;
    gap: 0px;
    margin: 0;
  }
  .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: fit-content;
  }
  .row-group {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
  }
  input {
    width: 80%;
  }
  button {
    margin: 20px 0;
  }
}
</style>
