<template>
  <div class="footer">
    <div class="contact">
      <h4>Any questions?</h4>
      <p>Contact: Anna.kohout@outlook.com</p>
    </div>
    <div class="copyright">© Anna Banana Bakery</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footer {
  width: 100%;
  height: fit-content;
  background-color: var(--background);
  border-top: 1px solid var(--accent);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.copyright {
  font-size: 0.8rem;
  margin: 0px;
}
h4 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}
@media (max-width: 768px) {
  h4 {
    font-size: 1.2rem;
  }
}
</style>
